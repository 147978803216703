<template>
  <el-col :span="span" :sm="sm" :xs="xs" :md="md" :lg="lg" :xl="xl" class="confirm-info-item" :style="cssVars">
    <h5 v-text="title" />
    <img :src="value" v-if="type=='Image'" />
    <span v-text="value" v-else />
  </el-col>
</template>

<style scoped>
.confirm-info-item {
  margin-bottom: 10px;
  font-size: 16px;
  color: #6f6f6f;
}

.confirm-info-item h5 {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  color: #333333;
}

.confirm-info-item h5,
.confirm-info-item span {
  line-height: var(--line-height);
}

.confirm-info-item img {
  width: 165px;
  height: 212px;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  padding: 5px;
}

</style>

<script>
export default {
  name: "ConfirmInforationItem",
  props: {
    title: String,
    type: {
      type: String,
      value: "String",
    },
    value: [String, Number, Date],
    lineHeight: {
      type: [String, Number],
      default: 30
    },
    span: {
      type: Number,
      default: 24
    },
    xs: {
      type: [Number, Object],
      default: 24,
    },
    sm: {
      type: [Number, Object],
      default: 12,
    },
    md: [Number, Object],
    lg: [Number, Object],
    xl: [Number, Object]
  },
  computed: {
    cssVars() {
      return {
        "--line-height": this.lineHeight + "px"
      }
    }
  }
}
</script>
