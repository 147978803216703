<template>
  <pcis-steps
    :form-type="formType"
    @prev-click="onPrevClick" 
    @next-click="onNextClick"
    ref="steps"
  >
    <div class="wrapper">
      <div class="box1">
        <el-card :header="t_cm('title')">
          <el-row>
            <info-item
              :title="t_cm('employer.label')"
              :value="companyName"
              :sm="24"
            />
          </el-row>
          <el-row>
            <info-item v-if="form.staffId" :title="t_cm('staff.label')" :value="form.staffId" />
          </el-row>
        </el-card>
      </div>
      <div class="box2">
        <el-card :header="t_pe('title')">
          <el-row>
            <info-item :title="t_pe('name.eng.label')" :value="form.name" />
            <info-item
              v-if="form.nameChi"
              :title="t_pe('name.chi')"
              :value="form.nameChi"
            />
          </el-row>
          <el-row>
            <info-item
              :title="t_pe('gender.label')"
              :value="getOptionsLable(tm_pe('gender.options'), form.gender)"
            />
            <info-item
              :title="t_pe('birthday.label')"
              :value="birthdayFormat()"
            />
          </el-row>
          <el-row>
            <info-item
              :title="t_pe('identity.label')"
              :value="showIdentity()"
            />
            <!-- <info-item
              :title="t_pe('country.label')"
              :value="countryName"
            /> -->
          </el-row>
          <el-row>
            <info-item
              v-if="form.phone"
              :title="t_pe('phone.office.label')"
              :value="form.phone"
            />
            <info-item
              :title="t_pe('phone.mobile.label')"
              :value="form.mobile"
            />
          </el-row>
          <el-row>
            <info-item 
              :title="t_pe('email.label')" 
              :value="form.email"
              style="word-break: break-word;"
            />
          </el-row>
          <el-row v-if="form.type == 'N'">
            <info-item
              :title="t_pe('address.address1.label')"
              :value="form.address1"
              :sm="24"
              style="word-break: break-word;"
            />
            <info-item
              :title="t_pe('address.address2.label')"
              :value="form.address2"
              :sm="24"
              style="word-break: break-word;"
            />
            <info-item
              :title="t_pe('address.address3.label')"
              :value="form.address3"
              :sm="24"
              style="word-break: break-word;"
            />
            <info-item
              :title="t_pe('address.district.label')"
              :value="form.district"
              :sm="24"
            />
            <info-item
              :title="t_pe('address.area.label')"
              :value="form.area"
              :sm="24"
            />
          </el-row>
        </el-card>
      </div>
      <div class="box3">
        <el-card :header="t_oc(`title.${form.type}`)">
          <div v-if="form.type == 'N'">
            <el-row>
              <info-item
                :title="t_oc('language.label')"
                :value="getOptionsLable(tm_oc('language.options'), form.language)"
                :sm="24"
              />
            </el-row>
            <el-row>
              <info-item
                :title="t_oc('photo.label')"
                :value="form.photo"
                type="Image"
              />
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <info-item
                :title="t_oc('label')"
                :value="`${form.octopus.number}(${form.octopus.checksum})`"
                :sm="24"
              />
            </el-row>
          </div>
        </el-card>
      </div>
    </div>
  </pcis-steps>
  <el-dialog custom-class="messageBox confirm" v-model="dialogFormVisible" @open="getFullAddr">
    <template #title>
      <p><b>{{ $t(`personal.dialog.confirmation.title.${form.type}`) }}</b></p>
    </template>
    <div v-if="form.type == 'N'">
      <p style="margin:0px 0px 10px 0px;">{{ $t('personal.dialog.confirmation.address') }} </p>
      <p class="nomargin" v-for="(item, index) in fullAddr"
        :key="item"
      >
        {{ item }}<span v-if="index !== fullAddr.length - 1">,</span>
      </p>
    </div>
    <el-form style="word-break:break-word;" @submit.prevent="onSubmit">
      <el-form-item :class="isInvalid(v$.dob.$error)">
        <p style="line-height: 1.5; margin: 10px 0px;">
          <u>{{ $t('personal.dialog.confirmation.dob.title') }} </u>
          <br />
          {{ $t('personal.dialog.confirmation.dob.text') }}</p>
        <el-input type="tel" :maxlength="8" v-model.trim="dob" @focus="v$.dob.$reset()" @blur="v$.dob.$touch" @keypress="handleSubmit"/>
        <div class="invalid-msg" v-if="v$.dob.$errors.length">{{ v$.dob.$errors[0].$message }}</div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{ $t("common.button.cancel") }}</el-button>
        <el-button type="primary" @click="confirmDialog">{{ $t("common.button.confirm") }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
* {
  font-size: 16px;
}
@media screen and (min-width:769px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    column-gap: 20px;
  }

  .box1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
  }

  .box2 {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .box3 {
    grid-column-start: 1;
    grid-row-start: 2;
  }

}

div >>> .el-card__header {
  font-size: 16px;
  font-weight: bold;
}

.el-form-item {
  display: block;
}

</style>

<script>
import PersonalStepForm from "@/components/PersonalStepForm.vue";
import moment from "moment";
import infoItem from "@/components/ConfirmInformationItem.vue";
import application from "@/http/apis/personal/application";
import master from "@/http/apis/master";
// import { handlePersonalException, isInvalid } from "../../../libs/common";
import { handlePersonalException, isInvalid, authenticateToken, parseJWT } from "../../../libs/common";
import { ElLoading } from 'element-plus'
import useVuelidate from '@vuelidate/core'
import * as validators from '@/libs/i18n-validators'
import { WarningAlert } from "../../../plugins/message";


export default {
  name: "PersonalConfirm",
  extends: PersonalStepForm,
  components: { infoItem },
  setup() {
    return { 
      v$: useVuelidate({
        $lazy: true,
        $autoDirty: false
      }) 
    }
  },
  data() {
    return {
      companyName: null,
      countryName: null,
      dialogFormVisible: false,
      dob: null,
      fullAddr: []
    }
  },
  validations() {
    return {
      dob: {
        required: validators.required,
        dobNotMatched: validators.confirmDOB(moment(this.form.birthday).format("YYYYMMDD"))
      }
    }
  },
  methods: {
    onSubmit(e) {
        console.log(e);
    },
    handleSubmit(event) {
      if(event.keyCode == 13) {
        this.confirmDialog();
      }
    },
    getFullAddr() {
      let address = [];
      if (this.form.address1) address.push(this.form.address1); 
      if (this.form.address2) address.push(this.form.address2); 
      if (this.form.address3) address.push(this.form.address3);
      address.push(this.form.district); 
      address.push(this.form.area); 
      this.fullAddr = address;
      // this.fullAddr = `${address.join(", ")}, ${this.form.district??""}, ${this.form.area??""}`;
    },
    getOptionsLable(options, value) {
      for (let item of options) {
        if (item.value == value) {
          return item.label;
        }
      }
    },
    loadData() {
      let locale = this.$i18n.locale;
      master.getCompanyByCode(locale, this.form.companyCode).then((response) => {
        this.companyName = response.data.text
      });
      master.getCountryByCode(locale, this.form.country).then((response) => {
        this.countryName = response.data.text
      })
    },
    birthdayFormat() {
      return moment(this.form.birthday).format("YYYY-MM-DD");
    },
    showIdentity() {
      let identity = this.form.identity;

      let type = identity.type;

      let idType = this.getOptionsLable(this.tm_pe("identity.options"), type);

      let number = identity.number;
      if (identity.type == 0) {
        number = `${number}(${identity.checksum})`;
      }
      return `${idType} - ${number}`;
    },
    onPrevClick(event) {
      event.goPrev();
    },
    onNextClick() {
      this.dialogFormVisible = true;
    },
    async confirmDialog() {
      let res = await this.v$.dob.$validate();
      if (res) {
        this.dialogFormVisible = false;
        let personal = this.$store.state.personal;
        // if (personal.form.type == "N") {
        //   this.$refs.steps.goNext();
        // } else {
        const loadingInstance = ElLoading.service({
          text: this.$i18n.t("common.loading.process")
        });
        
        // proposed change - Start
        let jwt = this.$store.getters.personalJWT;
        let res = await authenticateToken(parseJWT(jwt).token, false);
        personal.jwt = jwt;
        this.$store.commit("updateForm", personal.form);
        if (!res) {
          WarningAlert(this.$i18n.t("exceptions.default"))
          loadingInstance.close()
          return;
        }
        // proposed change - End

        application.postSubmitForm(personal.jwt, personal.form).then((res)=> {
          this.$store.commit("updatePersonalAppNo", res.data.id);
          this.$refs.steps.goNext();
        }).catch((err) => {
          handlePersonalException(err)
        }).finally(() => {
          loadingInstance.close();
        })
        // }
      }
    },
    isInvalid(invalid, extraClass = "") {
      return isInvalid(invalid, extraClass)
    }
  },
  created() {
    this.loadData();
  },
  beforeRouteUpdate() {
    this.loadData();
  }
};
</script>
